.bg {
  background: #272d42;
}
.blue {
  font-size: 14px;
  color: #2F7DF6;
}
.g-radio {
  display: flex;
  align-items: center;
}
.g-radio .radio {
  width: 16px;
  height: 16px;
  background: transparent;
  border-radius: 50%;
  border: 1px solid #c4cad7;
  display: flex;
  overflow: hidden;
  justify-items: center;
  align-items: center;
  margin: 0 8px 0 0;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.g-radio .radio::after {
  display: block;
  content: " ";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 auto;
  transition: all 0.3s ease-in-out;
}
.g-radio-checked .radio {
  border: 1px solid #2F7DF6;
}
.g-radio-checked .radio::after {
  background: #2F7DF6;
}
.g-radio-disabled .radio {
  background: #9ca5b0;
}
.g-radio-disabled .radio::after {
  background: none;
}
.g-radio-suffix {
  font-size: 14px;
  color: #ffffff;
}
