.bg {
  background: #272d42;
}
.blue {
  font-size: 14px;
  color: #2F7DF6;
}
.g-btn {
  display: inline-block;
  border: 0;
  text-align: center;
  transition: all 0.25s ease-out;
  vertical-align: middle;
  font-size: 14px;
  outline: none;
  padding: 6px 20px;
  background: #2F7DF6;
  color: #ffffff;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
}
.g-btn:disabled {
  cursor: not-allowed;
  background: #60677e;
  color: #9ca5b0;
}
.g-btn:disabled:hover {
  background: #60677e;
  color: #9ca5b0;
}
.g-btn:hover {
  opacity: 0.6;
}
.g-btn-red {
  background: #ff5361;
}
.g-btn-green {
  background: #08af7f;
}
.g-btn-group {
  display: flex;
  width: 100%;
  border-top: 1px solid #424c6d;
}
.g-btn-group .g-btn {
  flex: 1;
  border-radius: 0;
}
.g-btn-group.g-btn-group-justify {
  display: flex;
  width: 100%;
}
.g-btn-group.g-btn-group-justify .g-btn,
.g-btn-group.g-btn-group-justify .g-btn-hollow {
  flex: 1;
}
