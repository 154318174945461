.bg {
  background: #272d42;
}
.blue {
  font-size: 14px;
  color: #2F7DF6;
}
:global(.rc-menu) {
  outline: none;
  list-style: none;
  border-radius: 3px;
}
:global(.rc-menu)-hidden {
  display: none;
}
:global(.rc-menu)-collapse {
  overflow: hidden;
}
:global(.rc-menu)-collapse-active {
  transition: height 0.3s ease-out;
}
:global(.rc-menu)-item-group-list {
  margin: 0;
  padding: 0;
}
:global(.rc-menu)-item-group-title {
  color: rgba(255, 255, 255, 0.6);
  line-height: 1.5;
  padding: 8px 10px;
}
:global(.rc-menu)-item-active,
:global(.rc-menu)-submenu-active > :global(.rc-menu)-submenu-title {
  background-color: #2F7DF6;
}
:global(.rc-menu)-item-active:first-child,
:global(.rc-menu)-submenu-active > :global(.rc-menu)-submenu-title:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
:global(.rc-menu)-item-active:last-child,
:global(.rc-menu)-submenu-active > :global(.rc-menu)-submenu-title:last-child {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
:global(.rc-menu)-item-selected {
  transform: translateZ(0);
}
:global(.rc-menu)-submenu-selected {
  background-color: #2F7DF6;
}
:global(.rc-menu) > li:global(.rc-menu)-submenu {
  padding: 0;
}
:global(.rc-menu)-horizontal:global(.rc-menu)-sub,
:global(.rc-menu)-vertical:global(.rc-menu)-sub,
:global(.rc-menu)-vertical-left:global(.rc-menu)-sub,
:global(.rc-menu)-vertical-right:global(.rc-menu)-sub {
  min-width: 60px;
  margin-top: 0;
  background: #272d42;
  color: #ffffff;
  font-size: 12px;
  cursor: pointer;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
}
:global(.rc-menu)-horizontal:global(.rc-menu)-sub a,
:global(.rc-menu)-vertical:global(.rc-menu)-sub a,
:global(.rc-menu)-vertical-left:global(.rc-menu)-sub a,
:global(.rc-menu)-vertical-right:global(.rc-menu)-sub a {
  color: #ffffff;
}
:global(.rc-menu)-item,
:global(.rc-menu)-submenu-title {
  margin: 0;
  position: relative;
  display: block;
  padding: 7px;
  white-space: nowrap;
}
:global(.rc-menu) > :global(.rc-menu)-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  padding: 0;
  line-height: 0;
  background-color: #e5e5e5;
}
:global(.rc-menu)-submenu-popup {
  position: absolute;
  z-index: 10;
}
:global(.rc-menu) .rc-menu-submenu-title .anticon,
:global(.rc-menu) .rc-menu-item .anticon {
  width: 14px;
  height: 14px;
  margin-right: 8px;
  top: -1px;
}
:global(.rc-menu)-horizontal {
  border: none;
  box-shadow: none;
}
:global(.rc-menu)-horizontal > .rc-menu-item,
:global(.rc-menu)-horizontal > .rc-menu-submenu > .rc-menu-submenu-title {
  padding: 15px 20px;
}
:global(.rc-menu)-horizontal > .rc-menu-submenu,
:global(.rc-menu)-horizontal > .rc-menu-item {
  float: left;
  border-bottom: 2px solid transparent;
}
:global(.rc-menu)-horizontal > .rc-menu-submenu-active,
:global(.rc-menu)-horizontal > .rc-menu-item-active {
  background-color: #2F7DF6;
  color: rgba(255, 255, 255, 0.6);
}
:global(.rc-menu)-horizontal:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}
:global(.rc-menu)-vertical,
:global(.rc-menu)-vertical-left,
:global(.rc-menu)-vertical-right,
:global(.rc-menu)-inline {
  padding: 12px 0;
}
:global(.rc-menu)-vertical > .rc-menu-item,
:global(.rc-menu)-vertical-left > .rc-menu-item,
:global(.rc-menu)-vertical-right > .rc-menu-item,
:global(.rc-menu)-inline > .rc-menu-item,
:global(.rc-menu)-vertical > .rc-menu-submenu > .rc-menu-submenu-title,
:global(.rc-menu)-vertical-left > .rc-menu-submenu > .rc-menu-submenu-title,
:global(.rc-menu)-vertical-right > .rc-menu-submenu > .rc-menu-submenu-title,
:global(.rc-menu)-inline > .rc-menu-submenu > .rc-menu-submenu-title {
  padding: 12px;
}
:global(.rc-menu)-vertical .rc-menu-submenu-arrow,
:global(.rc-menu)-vertical-left .rc-menu-submenu-arrow,
:global(.rc-menu)-vertical-right .rc-menu-submenu-arrow,
:global(.rc-menu)-inline .rc-menu-submenu-arrow {
  display: inline-block;
  font: normal normal normal 14px/1 iconfontshble;
  font-size: inherit;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  position: absolute;
  right: 16px;
  line-height: 1.5em;
}
:global(.rc-menu)-vertical .rc-menu-submenu-arrow:before,
:global(.rc-menu)-vertical-left .rc-menu-submenu-arrow:before,
:global(.rc-menu)-vertical-right .rc-menu-submenu-arrow:before,
:global(.rc-menu)-inline .rc-menu-submenu-arrow:before {
  content: "\E61F";
}
:global(.rc-menu)-inline .rc-menu-submenu-arrow {
  transform: rotate(90deg);
  transition: transform 0.3s;
}
:global(.rc-menu)-inline .rc-menu-submenu-open > .rc-menu-submenu-title .rc-menu-submenu-arrow {
  transform: rotate(-90deg);
}
:global(.rc-menu)-vertical:global(.rc-menu)-sub,
:global(.rc-menu)-vertical-left:global(.rc-menu)-sub,
:global(.rc-menu)-vertical-right:global(.rc-menu)-sub {
  padding: 0;
}
:global(.rc-menu)-sub:global(.rc-menu)-inline {
  padding: 0;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
:global(.rc-menu)-sub:global(.rc-menu)-inline > .rc-menu-item,
:global(.rc-menu)-sub:global(.rc-menu)-inline > .rc-menu-submenu > .rc-menu-submenu-title {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 0;
}
:global(.rc-menu)-open-slide-up-enter,
:global(.rc-menu)-open-slide-up-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-play-state: paused;
}
:global(.rc-menu)-open-slide-up-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  opacity: 1;
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-play-state: paused;
}
:global(.rc-menu)-open-slide-up-enter:global(.rc-menu)-open-slide-up-enter-active,
:global(.rc-menu)-open-slide-up-appear:global(.rc-menu)-open-slide-up-appear-active {
  animation-name: rcMenuOpenSlideUpIn;
  animation-play-state: running;
}
:global(.rc-menu)-open-slide-up-leave:global(.rc-menu)-open-slide-up-leave-active {
  animation-name: rcMenuOpenSlideUpOut;
  animation-play-state: running;
}
@keyframes rcMenuOpenSlideUpIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
}
@keyframes rcMenuOpenSlideUpOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
}
:global(.rc-menu)-open-zoom-enter,
:global(.rc-menu)-open-zoom-appear {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-play-state: paused;
}
:global(.rc-menu)-open-zoom-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-play-state: paused;
}
:global(.rc-menu)-open-zoom-enter:global(.rc-menu)-open-zoom-enter-active,
:global(.rc-menu)-open-zoom-appear:global(.rc-menu)-open-zoom-appear-active {
  animation-name: rcMenuOpenZoomIn;
  animation-play-state: running;
}
:global(.rc-menu)-open-zoom-leave:global(.rc-menu)-open-zoom-leave-active {
  animation-name: rcMenuOpenZoomOut;
  animation-play-state: running;
}
@keyframes rcMenuOpenZoomIn {
  0% {
    opacity: 0;
    transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}
@keyframes rcMenuOpenZoomOut {
  0% {
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform: scale(0, 0);
  }
}
