.bg {
  background: #272d42;
}
.blue {
  font-size: 14px;
  color: #2F7DF6;
}
.g-loadingBox {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  min-height: 20px;
}
.g-loadingContent {
  display: flex;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) translateZ(0);
  z-index: 10;
}
.g-loadingMask {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}
