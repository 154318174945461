.bg {
  background: #272d42;
}
.blue {
  font-size: 14px;
  color: #2F7DF6;
}
.g-layer {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 200;
  transform: translate(0, 0) translateZ(0);
}
.g-layer.hide {
  transform: translate(-150%, 0) translateZ(0);
}
.g-layer.hide .layer-inner {
  opacity: 0;
  transform: translate(-50%, -50%) translateZ(0) scale(0.1, 0.1);
}
.g-layer.close .layer-inner {
  opacity: 0;
  transform: translate(-50%, -50%) translateZ(0) scale(0.1, 0.1);
}
.g-layer .layer-inner {
  width: 550px;
  background: #333c5a;
  position: absolute;
  left: 50%;
  top: 50%;
  transition: all 0.2s cubic-bezier(0.39, 0.58, 0.57, 1);
  transform: translate(-50%, -50%) scale(1, 1) translateZ(0);
  opacity: 1;
  border-radius: 5px;
}
.g-layer.android .layer-inner {
  transform: translate(-50%, -100%) scale(1, 1) translateZ(0);
}
.g-layer .layer-loading {
  width: 300px;
  padding: 20px 0;
  text-align: center;
}
.g-layer .layer-title {
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  line-height: 60px;
  background: #333c5a;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid #424c6d;
}
.g-layer .layer-content {
  color: #ffffff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.g-layer .g-btn {
  border: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.g-layer .g-btn-group {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
}
.g-layer .showCloseBtn {
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 100;
  color: #c4cad7;
  cursor: pointer;
}
.g-border-l {
  border-left: 1px solid #ffffff;
}
