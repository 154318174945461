.bg {
  background: #272d42;
}
.blue {
  font-size: 14px;
  color: #2F7DF6;
}
:global .rc-slider-rail {
  background: #2b3555;
}
:global .rc-slider-track {
  background: #2F7DF6;
}
:global .rc-slider-handle {
  border: 2px solid #2F7DF6;
  background: #2b3555;
}
:global .rc-slider-handle:active,
:global .rc-slider-handle:focus {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
}
:global .rc-slider-tooltip-inner {
  padding: 0;
  min-width: 40px;
  height: 30px;
  font-size: 12px;
  line-height: 30px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  background-color: #2b3555;
  border-radius: 0px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}
:global .rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  border-top-color: #2b3555;
}
