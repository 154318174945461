.bg {
  background: #272d42;
}
.blue {
  font-size: 14px;
  color: #2F7DF6;
}
.g-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #333c5a;
}
.g-table .theader {
  display: flex;
  height: 40px;
  align-items: center;
  color: #9ca5b0;
  font-size: 12px;
}
.g-table .theader div {
  flex: 1;
}
.g-table .theader div:first-child {
  padding: 0 0 0 20px;
}
.g-table .tbody {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  border-top: 1px solid #424c6d;
}
.g-table .item {
  display: flex;
  height: 40px;
  align-items: center;
  color: #ffffff;
  border-bottom: 1px solid #424c6d;
}
.g-table .item div {
  flex: 1;
  white-space: nowrap;
}
.g-table .item div:first-child {
  padding: 0 0 0 20px;
}
.g-table .item div .action {
  color: #64b9fc;
}
.g-table .item:hover {
  background: #2b3555;
}
.g-table .item .grey {
  color: #9ca5b0;
}
.g-table .item .grey02 {
  color: #c4cad7;
}
.g-table .noresult {
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.6);
}
