.bg {
  background: #272d42;
}
.blue {
  font-size: 14px;
  color: #2F7DF6;
}
:global .react-tabs__tab {
  padding: 12px 16px;
  font-size: 16px;
  margin: 0 20px;
  color: #ffffff;
  user-select: none;
  border: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  bottom: 0;
  transition: all 0.3s ease-in-out;
}
:global .react-tabs__tab:focus {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}
:global .react-tabs__tab:hover {
  color: #2F7DF6;
}
:global .react-tabs__tab-list {
  margin: 0;
}
:global .react-tabs__tab--selected {
  background: none;
  color: #2F7DF6;
  border: 0;
  border-bottom: 2px solid #2F7DF6;
  border-radius: 0;
}
:global .react-tabs__tab--selected:focus {
  border-bottom: 2px solid #2F7DF6;
}
:global .react-tabs__tab--selected:focus::after {
  display: none;
}
