@bg : rgba(39,45,66,1); // body 整体背景色
@bg2 : #14161f; // title 背景色
@bg3 : #1D1F24; // content 背景色
@bg4 : rgba(0,0,0,0.3); // 盘口图标背景色
@bg5 : rgba(0,0,0,0.2); // 进度条背景色
@bg6 : #24262B; // 进度条拖动点背景色
@bg7: #212328; // 表格背景色
@bg8 : rgba(31, 136, 229, 0.15); // 按钮hover 背景色
@bg9: rgba(31, 136, 229, 0.3); // 按钮hover 边框色
@bg10 : rgba(0,0,0,0.15); // 首页 币列表选项，选中背景色
@bg11 : #33363E; // 登录注册页body背景色

@bg12: rgba(43, 54, 73, 0.8); // 公共 title background
@bg13: rgba(24, 30, 41, 1); // 公共 content table background,
@bg14: rgba(31, 136, 229, 0.05);
@bg15: rgba(31, 136, 229, 0.2);

@bg16: rgba(38, 49, 66, 1); // layer 背景色
@bg17: rgba(43, 54, 73, 0.8); // layer title 背景色

@bg18 : rgba(34,40,56,1); // 个人中心

@bg19: rgba(24, 29, 32, 0.8); // header bg

@bg21: rgba(43, 54, 73, 0.6); // 盘口中间背景色

@bg22: rgba(24, 30, 41, 1); // 行情内容背景色

@bg23: rgba(36, 42, 59, 1); // table item hover

@c1: #404249;
@c2 : #b5b5b8;
@c3: #2b2d35;
@c4 : #7f8185;
@c5: #616f82;
@c6: #167063; // 进度条拖动点 边框色
@c7: #134842; // 已有进度条背景色
@c8: #8f3e3c; // 红色进度条 拖动点边框
@c9: #5c2a2a; // 红色 已有进度条背景色
@c10: #9ce4d7; // 绿色按钮hover背景色
@c11 : rgba(252, 87, 77, 1); // 下单红色按钮
@c12 : #B23943; // 下单红色按钮hover
@c13: #08af7f; // 下单绿色按钮
@c14: #057a58; // 下单绿色按钮hover
@c15: #3c414a;
@c16: #d9d9d9; // radio 边框色

@c17: rgba(135, 150, 176, 1); // form label color
@c18: rgba(135, 150, 176, 0.7); // form label color

@c19: rgba(104, 119, 139, 1); // tokeninfo text color

@bg20: #1d2431; // 行情左侧bg
@c20: #68778b;
@c21: #313a4f;
@c22: #777b83;
@c23: #bbbdc1;
@c24: #afbdd1;
@c25: #263142;
@c26: #1e2734;
@c27: #8796b0;
@c28: #757980;
@c29: #141922;
@c30: #64b9fc;
@c31: #181d20;
@c32: #171c20;

@red: #ff5361;

@black: #000;
@2b: #2b2b2b;

@input_bg: #1a202c;
@input_border: #3a4f73;
@input_placeholder: #596e91;
@input_focus_border: #2f7df6;

@blue_hover : rgba(31,136,229,.7);

@fontfamily: "Open Sans", "Helvetica Neue", BlinkMacSystemFont, "PingFang SC",
  "Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
@ft20 : 20px;
@ft18 : 18px;
@ft16 : 16px;
@ft14: 14px;
@ft12 : 12px;
@ft10 : 10px;

@fff100: rgba(255, 255, 255, 1); // 文字颜色
@fff95: rgba(255, 255, 255, 0.95); // 文字颜色
@fff90: rgba(255, 255, 255, 0.9); // 文字颜色
@fff85: rgba(255, 255, 255, 0.85); // 文字颜色
@fff80: rgba(255, 255, 255, 0.8); // 文字颜色
@fff75: rgba(255, 255, 255, 0.75); // 文字颜色
@fff70: rgba(255, 255, 255, 0.7); // 文字颜色
@fff60: rgba(255, 255, 255, 0.6); // 文字颜色
@fff50: rgba(255, 255, 255, 0.5); // 文字颜色
@fff45: rgba(255, 255, 255, 0.45); // 文字颜色
@fff40: rgba(255, 255, 255, 0.4); // 文字颜色
@fff35: rgba(255, 255, 255, 0.35); // 文字颜色
@fff30: rgba(255, 255, 255, 0.3); // 文字颜色
@fff25: rgba(255, 255, 255, 0.25); // 文字颜色
@fff20: rgba(255, 255, 255, 0.2); // 文字颜色
@fff15: rgba(255, 255, 255, 0.15); // 文字颜色
@fff10: rgba(255, 255, 255, 0.1); // 文字颜色
@fff05: rgba(255, 255, 255, 0.05); // 文字颜色

@bg: #272d42; // body 整体背景色
@bg2: #445078; // 登录注册背景渐变色
@bg3: rgba(130, 140, 185, 1); // 注册内容背景渐变色结束色
@bg4: rgba(40, 50, 80, 0); // 注册内容背景渐变开始色
@bg5: #3b4361; // 注册内容背景渐变色结束色
@bg6: #161a2a; // 注册内容背景渐变开始色
@bg7: #1a202c; //行情页最新价背景色
@bg001: #1d2431; // header,footer导航栏背景色,行情页内容背景色
@bg002: #333c5a; // 表格背景色,注册模板背景色
@bg003: #2b3555; // 输入框键入态背景色,行情页滑动条背景色
@bg004: #191f32; // 首页特点模块背景色
@bg005: rgba(10, 14, 23, 0.6); // 首页banner说明背景色
@bg006: rgba(47, 125, 246, 0.6); // 首页特点模块点亮背景色
@bg007: #434f78; // menu hover背景色
@bg008: #274d80; // tab标签背景色
@bg009: #60677e; // disabled按钮背景
@bg010: #263142; // 弹框按钮hover背景色
@bg011: rgba(43, 53, 85, 0.4); // 身份证照片覆盖蒙层背景色

@bgfff10: rgba(255, 255, 255, 0.1); // 二维码背景色
@bg00010: rgba(0, 0, 0, 0.1); // 谷歌验证二维码背景色

// 焦点色
@white: #ffffff;
@blue : #2F7DF6;
@green: #08af7f;
@red: #ff5361;
@red_bg: rgba(255, 83, 97, 0.1); // 盘口进度条背景色
@green_bg: rgba(8, 175, 127, 0.1); // 盘口进度条背景色
@yellow: #fcb71e;
@light_blue: #64b9fc;

@c001: #c4cad7; // 一级标题颜色
@c002: #9ca5b0; // 二级标题颜色、disabled文字颜色
@c003: #68778b; // 副标题文字颜色、输入框未输入状态颜色、浮层、列表hover背景色
@c004: #4e5c6d; // 焦点色
@c005: #181e29; // 行情页，订单表格背景色
@c006: #202734; // 行情页，订单表格houver
@c007: #232933; // 行情页，订单表格border

@b001: #424c6d; // 列表分割线
@b002: #67759f; // 输入框默认边框、icon、竖线分割线

.bg {
  background: @bg;
}

.blue {
  font-size: @ft14;
  color: @blue;
}
