@font-face {
  font-family: "iconfont"; /* project id 839010 */
  src: url("../iconfonts/iconfont.eot");
  src: url("../iconfonts/iconfont.eot?#iefix") format("embedded-opentype"),
    url("../iconfonts/iconfont.woff") format("woff"),
    url("../iconfonts/iconfont.ttf") format("truetype"),
    url("../iconfonts/iconfont.svg#iconfont") format("svg");
}

.b-icon {
  display: inline-block;
  font-family: "iconfont";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  font-size: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: middle;

  span {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 24px;
    padding: 0 5px;
  }

  &-googleplay:before {
    content: "\e688";
  }

  &-coingecko:before {
    content: "\e687";
  }

  &-feixiaohao:before {
    content: "\e684";
  }

  &-mytoken:before {
    content: "\e685";
  }

  &-coinmarketcap:before {
    content: "\e686";
  }

  &-delete1:before {
    content: "\e682";
  }

  &-save:before {
    content: "\e683";
  }

  &-levelunlimited:before {
    content: "\e681";
  }

  &-levelwithdraw:before {
    content: "\e67e";
  }

  &-levelcheck:before {
    content: "\e67f";
  }

  &-levellimited:before {
    content: "\e680";
  }

  &-triangle:before {
    content: "\e67c";
  }

  &-suspended1:before {
    content: "\e67d";
  }

  &-delete:before {
    content: "\e679";
  }

  &-checkedRadio2:before {
    content: "\e67b";
  }

  &-edit2:before {
    content: "\e67a";
  }

  &-flod:before {
    content: "\e677";
  }

  &-unflod:before {
    content: "\e678";
  }

  &-qq:before {
    content: "\e676";
  }

  &-testfight:before {
    content: "\e675";
  }

  &-medium:before {
    content: "\e66f";
  }

  &-linkedin:before {
    content: "\e670";
  }

  &-github:before {
    content: "\e671";
  }

  &-line:before {
    content: "\e672";
  }

  &-discord:before {
    content: "\e673";
  }

  &-biyong:before {
    content: "\e674";
  }

  &-count:before {
    content: "\e66e";
  }

  &-indicator:before {
    content: "\e66d";
  }

  &-contract:before {
    content: "\e66c";
  }

  &-dark:before {
    content: "\e669";
  }

  &-whiite:before {
    content: "\e66a";
  }

  &-cnyswitch:before {
    content: "\e66b";
  }

  &-download1:before {
    content: "\e656";
  }

  &-kline:before {
    content: "\e659";
  }

  &-Sort_left:before {
    content: "\e65a";
  }

  &-moreContent:before {
    content: "\e65d";
  }

  &-leftmore:before {
    content: "\e65e";
  }

  &-fullScreen:before {
    content: "\e65f";
  }

  &-Sort_right:before {
    content: "\e661";
  }

  &-moment:before {
    content: "\e662";
  }

  &-search1:before {
    content: "\e664";
  }

  &-check:before {
    content: "\e666";
  }

  &-screening:before {
    content: "\e667";
  }

  &-share1:before {
    content: "\e668";
  }

  &-order_down:before {
    content: "\e663";
  }

  &-order_up:before {
    content: "\e665";
  }

  &-slider:before {
    content: "\e62f";
  }

  &-notice:before {
    content: "\e660";
  }

  &-trade:before {
    content: "\e657";
  }

  &-asset:before {
    content: "\e658";
  }

  &-markets:before {
    content: "\e65b";
  }

  &-home:before {
    content: "\e65c";
  }

  &-back:before {
    content: "\e655";
  }

  &-search:before {
    content: "\e654";
  }

  &-paste1:before {
    content: "\e653";
  }

  &-brief:before {
    content: "\e652";
  }

  &-windows:before {
    content: "\e635";
  }

  &-weibo:before {
    content: "\e636";
  }

  &-telegram:before {
    content: "\e637";
  }

  &-apple:before {
    content: "\e638";
  }

  &-facebook:before {
    content: "\e63a";
  }

  &-android:before {
    content: "\e63b";
  }

  &-locked:before {
    content: "\e63c";
  }

  &-favorite:before {
    content: "\e63d";
  }

  &-favorited:before {
    content: "\e63e";
  }

  &-candels:before {
    content: "\e63f";
  }

  &-settings:before {
    content: "\e640";
  }

  &-day:before {
    content: "\e641";
  }

  &-night:before {
    content: "\e642";
  }

  &-announcement:before {
    content: "\e643";
  }

  &-pin:before {
    content: "\e644";
  }

  &-fullscreen:before {
    content: "\e645";
  }

  &-network:before {
    content: "\e646";
  }

  &-icon-test1:before {
    content: "\e648";
  }

  &-twitter:before {
    content: "\e64a";
  }

  &-intro:before {
    content: "\e639";
  }

  &-edit:before {
    content: "\e64b";
  }

  &-info_line:before {
    content: "\e64c";
  }

  &-appdownload:before {
    content: "\e64d";
  }

  &-announce:before {
    content: "\e64e";
  }

  &-download:before {
    content: "\e64f";
  }

  &-reddit:before {
    content: "\e650";
  }

  &-Icon_Authentication:before {
    content: "\e651";
  }

  &-dianhua:before {
    content: "\e609";
  }

  &-v:before {
    content: "\e69a";
  }

  &-loading:before {
    content: "\e615";
  }

  &-paypal:before {
    content: "\e89e";
  }

  &-send:before {
    content: "\e605";
  }

  &-kong:before {
    content: "\e600";
  }

  &-addPicture:before {
    content: "\e647";
  }

  &-usdt:before {
    content: "\e649";
  }

  &-alarm:before {
    content: "\e601";
  }

  &-arrowDown:before {
    content: "\e602";
  }

  &-close:before {
    content: "\e603";
  }

  &-attachment:before {
    content: "\e604";
  }

  &-filter:before {
    content: "\e606";
  }

  &-firstPage:before {
    content: "\e607";
  }

  &-fold:before {
    content: "\e608";
  }

  &-lastPage:before {
    content: "\e60a";
  }

  &-hidden:before {
    content: "\e60b";
  }

  &-moreAction:before {
    content: "\e60c";
  }

  &-nextPage:before {
    content: "\e60d";
  }

  &-prevPage:before {
    content: "\e60e";
  }

  &-Suspended:before {
    content: "\e60f";
  }

  &-unfold:before {
    content: "\e610";
  }

  &-unhidden:before {
    content: "\e611";
  }

  &-arrowUp:before {
    content: "\e612";
  }

  &-add:before {
    content: "\e613";
  }

  &-arrowRight:before {
    content: "\e614";
  }

  &-arrowLeft:before {
    content: "\e616";
  }

  &-checked:before {
    content: "\e617";
  }

  &-cancelled1:before {
    content: "\e61a";
  }

  &-finished1:before {
    content: "\e61d";
  }

  &-foldPage:before {
    content: "\e61e";
  }

  &-paste:before {
    content: "\e622";
  }

  &-lightsOFF:before {
    content: "\e625";
  }

  &-lightsON:before {
    content: "\e626";
  }

  &-transaction:before {
    content: "\e627";
  }

  &-unfoldPage:before {
    content: "\e628";
  }

  &-bankCard:before {
    content: "\e618";
  }

  &-wechatPay:before {
    content: "\e619";
  }

  &-alipay:before {
    content: "\e61b";
  }

  &-uncheck:before {
    content: "\e61c";
  }

  &-user:before {
    content: "\e61f";
  }

  &-checkedRadio:before {
    content: "\e620";
  }

  &-ucheckedRadio:before {
    content: "\e621";
  }

  &-time:before {
    content: "\e623";
  }

  &-code1:before {
    content: "\e624";
  }

  &-positive:before {
    content: "\e629";
  }

  &-reverse:before {
    content: "\e62a";
  }

  &-comment:before {
    content: "\e62b";
  }

  &-info:before {
    content: "\e62c";
  }

  &-praised:before {
    content: "\e62d";
  }

  &-upload:before {
    content: "\e62e";
  }

  &-logo:before {
    content: "\e631";
  }

  &-share:before {
    content: "\e632";
  }

  &-praise1:before {
    content: "\e633";
  }

  &-icon-test:before {
    content: "\e630";
  }

  &-wechat:before {
    content: "\e634";
  }
  &-youtube:before {
    content: "\e6a4";
  }
}
