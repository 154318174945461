.bg {
  background: #272d42;
}
.blue {
  font-size: 14px;
  color: #2F7DF6;
}
.g-dropdown {
  position: relative;
  width: 100%;
}
.g-dropdown .control {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  color: #68778b;
  cursor: default;
  outline: none;
  padding: 2px 0px 2px 10px;
  transition: all 200ms ease;
  width: 100%;
  height: 24px;
  font-size: 12px;
}
.g-dropdown .arrow {
  border-color: #68778b;
  border-style: solid;
  border-width: 1px 1px 0 0;
  content: " ";
  display: block;
  position: absolute;
  right: 8px;
  top: 5px;
  width: 8px;
  height: 8px;
  transform: rotateZ(135deg);
}
.g-dropdown .menu {
  background-color: #272d42;
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 300px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 900;
  -webkit-overflow-scrolling: touch;
  color: #ffffff;
}
.g-dropdown .menu .group > .title {
  padding: 8px 10px;
  color: #ffffff;
  font-weight: bold;
  text-transform: capitalize;
}
.g-dropdown .option {
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: block;
  padding: 12px 0px 10px 10px;
  transition: all 0.3s ease-in-out;
}
.g-dropdown .option:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}
.g-dropdown .option:hover {
  background-color: #2F7DF6;
}
.g-dropdown .option.selected {
  background-color: #2F7DF6;
}
.g-dropdown .noresults {
  box-sizing: border-box;
  color: #ffffff;
  cursor: default;
  display: block;
  padding: 8px 10px;
}
