//公共图标

@font-face {
  font-family: iconfontshble;
  src: url("../iconfonts/font.eot");
  src: url("../iconfonts/font.woff") format("woff"),
    url("../iconfonts/font.ttf") format("truetype"),
    url("../iconfonts/font.svg#iconfont") format("svg");
}

.g-icon {
  display: inline-block;
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  line-height: 1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  &:before {
    font-family: iconfontshble;
    display: block;
  }
}

// 公共图标
.g-icon-step-forward:before {
  content: "\E600";
}

.g-icon-step-backward:before {
  content: "\E601";
}

.g-icon-forward:before {
  content: "\E602";
}

.g-icon-backward:before {
  content: "\E603";
}

.g-icon-caret-right:before {
  content: "\E604";
}

.g-icon-caret-left:before {
  content: "\E605";
}

.g-icon-caret-down:before {
  content: "\E606";
}

.g-icon-caret-up:before {
  content: "\E607";
}

.g-icon-caret-circle-right:before,
.g-icon-circle-right:before,
.g-icon-right-circle:before {
  content: "\E608";
}

.g-icon-caret-circle-left:before,
.g-icon-circle-left:before,
.g-icon-left-circle:before {
  content: "\E609";
}

.g-icon-caret-circle-up:before,
.g-icon-circle-up:before,
.g-icon-up-circle:before {
  content: "\E60A";
}

.g-icon-caret-circle-down:before,
.g-icon-circle-down:before,
.g-icon-down-circle:before {
  content: "\E60B";
}

.g-icon-right-circle-o:before {
  content: "\E60C";
}

.g-icon-caret-circle-o-right:before,
.g-icon-circle-o-right:before {
  content: "\E60C";
}

.g-icon-left-circle-o:before {
  content: "\E60D";
}

.g-icon-caret-circle-o-left:before,
.g-icon-circle-o-left:before {
  content: "\E60D";
}

.g-icon-up-circle-o:before {
  content: "\E60E";
}

.g-icon-caret-circle-o-up:before,
.g-icon-circle-o-up:before {
  content: "\E60E";
}

.g-icon-down-circle-o:before {
  content: "\E60F";
}

.g-icon-caret-circle-o-down:before,
.g-icon-circle-o-down:before {
  content: "\E60F";
}

.g-icon-verticle-left:before {
  content: "\E610";
}

.g-icon-verticle-right:before {
  content: "\E611";
}

.g-icon-rollback:before {
  content: "\E612";
}

.g-icon-retweet:before {
  content: "\E613";
}

.g-icon-shrink:before {
  content: "\E614";
}

.g-icon-arrow-salt:before,
.g-icon-arrows-alt:before {
  content: "\E615";
}

.g-icon-reload:before {
  content: "\E616";
}

.g-icon-double-right:before {
  content: "\E617";
}

.g-icon-double-left:before {
  content: "\E618";
}

.g-icon-arrow-down:before {
  content: "\E619";
}

.g-icon-arrow-up:before {
  content: "\E61A";
}

.g-icon-arrow-right:before {
  content: "\E61B";
}

.g-icon-arrow-left:before {
  content: "\E61C";
}

.g-icon-down:before {
  content: "\E61D";
}

.g-icon-up:before {
  content: "\E61E";
}

.g-icon-right:before {
  content: "\E61F";
}

.g-icon-left:before {
  content: "\E620";
}

.g-icon-minus-square-o:before {
  content: "\E621";
}

.g-icon-minus-circle:before {
  content: "\E622";
}

.g-icon-minus-circle-o:before {
  content: "\E623";
}

.g-icon-minus:before {
  content: "\E624";
}

.g-icon-plus-circle-o:before {
  content: "\E625";
}

.g-icon-plus-circle:before {
  content: "\E626";
}

.g-icon-plus:before {
  content: "\E627";
}

.g-icon-info-circle:before {
  content: "\E628";
}

.g-icon-info-circle-o:before {
  content: "\E629";
}

.g-icon-info:before {
  content: "\E62A";
}

.g-icon-exclamation:before {
  content: "\E62B";
}

.g-icon-exclamation-circle:before {
  content: "\E62C";
}

.g-icon-exclamation-circle-o:before {
  content: "\E62D";
}

.g-icon-close-circle:before,
.g-icon-cross-circle:before {
  content: "\E62E";
}

.g-icon-close-circle-o:before,
.g-icon-cross-circle-o:before {
  content: "\E62F";
}

.g-icon-check-circle:before {
  content: "\E630";
}

.g-icon-check-circle-o:before {
  content: "\E631";
}

.g-icon-check:before {
  content: "\E632";
}

.g-icon-close:before,
.g-icon-cross:before {
  content: "\E633";
}

.g-icon-customer-service:before,
.g-icon-customerservice:before {
  content: "\E634";
}

.g-icon-credit-card:before {
  content: "\E635";
}

.g-icon-code-o:before {
  content: "\E636";
}

.g-icon-book:before {
  content: "\E637";
}

.g-icon-bars:before {
  content: "\E639";
}

.g-icon-question:before {
  content: "\E63A";
}

.g-icon-question-circle:before {
  content: "\E63B";
}

.g-icon-question-circle-o:before {
  content: "\E63C";
}

.g-icon-pause:before {
  content: "\E63D";
}

.g-icon-pause-circle:before {
  content: "\E63E";
}

.g-icon-pause-circle-o:before {
  content: "\E63F";
}

.g-icon-clock-circle:before {
  content: "\E640";
}

.g-icon-clock-circle-o:before {
  content: "\E641";
}

.g-icon-swap:before {
  content: "\E642";
}

.g-icon-swap-left:before {
  content: "\E643";
}

.g-icon-swap-right:before {
  content: "\E644";
}

.g-icon-plus-square-o:before {
  content: "\E645";
}

.g-icon-frown-circle:before,
.g-icon-frown:before {
  content: "\E646";
}

.g-icon-ellipsis:before {
  content: "\E647";
}

.g-icon-copy:before {
  content: "\E648";
}

.g-icon-menu-fold:before {
  content: "\E9AC";
}

.g-icon-mail:before {
  content: "\E659";
}

.g-icon-logout:before {
  content: "\E65A";
}

.g-icon-link:before {
  content: "\E65B";
}

.g-icon-area-chart:before {
  content: "\E65C";
}

.g-icon-line-chart:before {
  content: "\E65D";
}

.g-icon-home:before {
  content: "\E65E";
}

.g-icon-laptop:before {
  content: "\E65F";
}

.g-icon-star:before {
  content: "\E660";
}

.g-icon-star-o:before {
  content: "\E661";
}

.g-icon-folder:before {
  content: "\E662";
}

.g-icon-filter:before {
  content: "\E663";
}

.g-icon-file:before {
  content: "\E664";
}

.g-icon-exception:before {
  content: "\E665";
}

.g-icon-meh-circle:before,
.g-icon-meh:before {
  content: "\E666";
}

.g-icon-meh-o:before {
  content: "\E667";
}

.g-icon-shopping-cart:before {
  content: "\E668";
}

.g-icon-save:before {
  content: "\E669";
}

.g-icon-user:before {
  content: "\E66A";
}

.g-icon-video-camera:before {
  content: "\E66B";
}

.g-icon-to-top:before {
  content: "\E66C";
}

.g-icon-team:before {
  content: "\E66D";
}

.g-icon-tablet:before {
  content: "\E66E";
}

.g-icon-solution:before {
  content: "\E66F";
}

.g-icon-search:before {
  content: "\E670";
}

.g-icon-share-alt:before {
  content: "\E671";
}

.g-icon-setting:before {
  content: "\E672";
}

.g-icon-poweroff:before {
  content: "\E6D5";
}

.g-icon-picture:before {
  content: "\E674";
}

.g-icon-phone:before {
  content: "\E675";
}

.g-icon-paper-clip:before {
  content: "\E676";
}

.g-icon-notification:before {
  content: "\E677";
}

.g-icon-mobile:before {
  content: "\E678";
}

.g-icon-menu-unfold:before {
  content: "\E9AD";
}

.g-icon-inbox:before {
  content: "\E67A";
}

.g-icon-lock:before {
  content: "\E67B";
}

.g-icon-qrcode:before {
  content: "\E67C";
}

.g-icon-play-circle:before {
  content: "\E6D0";
}

.g-icon-play-circle-o:before {
  content: "\E6D1";
}

.g-icon-tag:before {
  content: "\E6D2";
}

.g-icon-tag-o:before {
  content: "\E6D3";
}

.g-icon-tags:before {
  content: "\E67D";
}

.g-icon-tags-o:before {
  content: "\E67E";
}

.g-icon-cloud-o:before {
  content: "\E67F";
}

.g-icon-cloud:before {
  content: "\E680";
}

.g-icon-cloud-upload:before {
  content: "\E681";
}

.g-icon-cloud-download:before {
  content: "\E682";
}

.g-icon-cloud-download-o:before {
  content: "\E683";
}

.g-icon-cloud-upload-o:before {
  content: "\E684";
}

.g-icon-environment:before {
  content: "\E685";
}

.g-icon-environment-o:before {
  content: "\E686";
}

.g-icon-eye:before {
  content: "\E687";
}

.g-icon-eye-o:before {
  content: "\E688";
}

.g-icon-camera:before {
  content: "\E689";
}

.g-icon-camera-o:before {
  content: "\E68A";
}

.g-icon-windows:before {
  content: "\E68B";
}

.g-icon-apple:before {
  content: "\E68C";
}

.g-icon-apple-o:before {
  content: "\E6D4";
}

.g-icon-android:before {
  content: "\E938";
}

.g-icon-android-o:before {
  content: "\E68D";
}

.g-icon-aliwangwang:before {
  content: "\E68E";
}

.g-icon-aliwangwang-o:before {
  content: "\E68F";
}

.g-icon-export:before {
  content: "\E691";
}

.g-icon-edit:before {
  content: "\E692";
}

.g-icon-circle-down-o:before {
  content: "\E693";
}

.g-icon-circle-down-:before {
  content: "\E694";
}

.g-icon-appstore-o:before {
  content: "\E695";
}

.g-icon-appstore:before {
  content: "\E696";
}

.g-icon-scan:before {
  content: "\E697";
}

.g-icon-file-text:before {
  content: "\E698";
}

.g-icon-folder-open:before {
  content: "\E699";
}

.g-icon-hdd:before {
  content: "\E69A";
}

.g-icon-ie:before {
  content: "\E69B";
}

.g-icon-file-jpg:before {
  content: "\E69C";
}

.g-icon-like:before {
  content: "\E64C";
}

.g-icon-like-o:before {
  content: "\E69D";
}

.g-icon-dislike:before {
  content: "\E64B";
}

.g-icon-dislike-o:before {
  content: "\E69E";
}

.g-icon-delete:before {
  content: "\E69F";
}

.g-icon-enter:before {
  content: "\E6A0";
}

.g-icon-pushpin-o:before {
  content: "\E6A1";
}

.g-icon-pushpin:before {
  content: "\E6A2";
}

.g-icon-heart:before {
  content: "\E6A3";
}

.g-icon-heart-o:before {
  content: "\E6A4";
}

.g-icon-pay-circle:before {
  content: "\E6A5";
}

.g-icon-pay-circle-o:before {
  content: "\E6A6";
}

.g-icon-smile-circle:before,
.g-icon-smile:before {
  content: "\E6A7";
}

.g-icon-smile-o:before {
  content: "\E6A8";
}

.g-icon-frown-o:before {
  content: "\E6A9";
}

.g-icon-calculator:before {
  content: "\E6AA";
}

.g-icon-message:before {
  content: "\E6AB";
}

.g-icon-chrome:before {
  content: "\E6AC";
}

.g-icon-github:before {
  content: "\E6AD";
}

.g-icon-file-unknown:before {
  content: "\E6AF";
}

.g-icon-file-excel:before {
  content: "\E6B0";
}

.g-icon-file-ppt:before {
  content: "\E6B1";
}

.g-icon-file-word:before {
  content: "\E6B2";
}

.g-icon-file-pdf:before {
  content: "\E6B3";
}

.g-icon-desktop:before {
  content: "\E6B4";
}

.g-icon-upload:before {
  content: "\E6B6";
}

.g-icon-download:before {
  content: "\E6B7";
}

.g-icon-pie-chart:before {
  content: "\E6B8";
}

.g-icon-unlock:before {
  content: "\E6BA";
}

.g-icon-calendar:before {
  content: "\E6BB";
}

.g-icon-windows-o:before {
  content: "\E6BC";
}

.g-icon-dot-chart:before {
  content: "\E6BD";
}

.g-icon-bar-chart:before {
  content: "\E6BE";
}

.g-icon-code:before {
  content: "\E6BF";
}

.g-icon-api:before {
  content: "\E951";
}

.g-icon-plus-square:before {
  content: "\E6C0";
}

.g-icon-minus-square:before {
  content: "\E6C1";
}

.g-icon-close-square:before {
  content: "\E6C2";
}

.g-icon-close-square-o:before {
  content: "\E6C3";
}

.g-icon-check-square:before {
  content: "\E6C4";
}

.g-icon-check-square-o:before {
  content: "\E6C5";
}

.g-icon-fast-backward:before {
  content: "\E6C6";
}

.g-icon-fast-forward:before {
  content: "\E6C7";
}

.g-icon-up-square:before {
  content: "\E6C8";
}

.g-icon-down-square:before {
  content: "\E6C9";
}

.g-icon-left-square:before {
  content: "\E6CA";
}

.g-icon-right-square:before {
  content: "\E6CB";
}

.g-icon-right-square-o:before {
  content: "\E6CC";
}

.g-icon-left-square-o:before {
  content: "\E6CD";
}

.g-icon-down-square-o:before {
  content: "\E6CE";
}

.g-icon-up-square-o:before {
  content: "\E6CF";
}

.g-icon-loading:before {
  content: "\E64D";
}
.g-icon-spin {
  animation: loading 1s infinite linear;
}
@keyframes loading {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.g-icon-loading-3-quarters:before {
  content: "\E6AE";
}

.g-icon-bulb:before {
  content: "\E649";
}

.g-icon-select:before {
  content: "\E64A";
}

.g-icon-addfile:before,
.g-icon-file-add:before {
  content: "\E910";
}

.g-icon-addfolder:before,
.g-icon-folder-add:before {
  content: "\E914";
}

.g-icon-switcher:before {
  content: "\E913";
}

.g-icon-rocket:before {
  content: "\E90F";
}

.g-icon-dingding:before {
  content: "\E923";
}

.g-icon-dingding-o:before {
  content: "\E925";
}

.g-icon-bell:before {
  content: "\E64E";
}

.g-icon-disconnect:before {
  content: "\E64F";
}

.g-icon-database:before {
  content: "\E650";
}

.g-icon-compass:before {
  content: "\E6DB";
}

.g-icon-barcode:before {
  content: "\E652";
}

.g-icon-hourglass:before {
  content: "\E653";
}

.g-icon-key:before {
  content: "\E654";
}

.g-icon-flag:before {
  content: "\E655";
}

.g-icon-layout:before {
  content: "\E656";
}

.g-icon-login:before {
  content: "\E657";
}

.g-icon-printer:before {
  content: "\E673";
}

.g-icon-sound:before {
  content: "\E6E9";
}

.g-icon-usb:before {
  content: "\E6D7";
}

.g-icon-skin:before {
  content: "\E6D8";
}

.g-icon-tool:before {
  content: "\E6D9";
}

.g-icon-sync:before {
  content: "\E6DA";
}

.g-icon-wifi:before {
  content: "\E6D6";
}

.g-icon-car:before {
  content: "\E6DC";
}

.g-icon-copyright:before {
  content: "\E6DE";
}

.g-icon-schedule:before {
  content: "\E6DF";
}

.g-icon-user-add:before {
  content: "\E6ED";
}

.g-icon-user-delete:before {
  content: "\E6E0";
}

.g-icon-usergroup-add:before {
  content: "\E6DD";
}

.g-icon-usergroup-delete:before {
  content: "\E6E1";
}

.g-icon-man:before {
  content: "\E6E2";
}

.g-icon-woman:before {
  content: "\E6EC";
}

.g-icon-shop:before {
  content: "\E6E3";
}

.g-icon-gift:before {
  content: "\E6E4";
}

.g-icon-idcard:before {
  content: "\E6E5";
}

.g-icon-medicine-box:before {
  content: "\E6E6";
}

.g-icon-red-envelope:before {
  content: "\E6E7";
}

.g-icon-coffee:before {
  content: "\E6E8";
}

.g-icon-trademark:before {
  content: "\E651";
}

.g-icon-safety:before {
  content: "\E6EA";
}

.g-icon-wallet:before {
  content: "\E6EB";
}

.g-icon-bank:before {
  content: "\E6EE";
}

.g-icon-trophy:before {
  content: "\E6EF";
}

.g-icon-contacts:before {
  content: "\E6F0";
}

.g-icon-global:before {
  content: "\E6F1";
}

.g-icon-shake:before {
  content: "\E94F";
}

.g-icon-fork:before {
  content: "\E6F2";
}

.g-icon-dashboard:before {
  content: "\E99A";
}

.g-icon-profile:before {
  content: "\E999";
}

.g-icon-table:before {
  content: "\E998";
}

.g-icon-warning:before {
  content: "\E997";
}

.g-icon-form:before {
  content: "\E996";
}

.g-icon-spin:before {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;
}

.g-icon-weibo-square:before {
  content: "\E6F5";
}

.g-icon-weibo-circle:before {
  content: "\E6F4";
}

.g-icon-taobao-circle:before {
  content: "\E6F3";
}

.g-icon-html5:before {
  content: "\E9C7";
}

.g-icon-weibo:before {
  content: "\E9C6";
}

.g-icon-twitter:before {
  content: "\E9C5";
}

.g-icon-wechat:before {
  content: "\E9C4";
}

.g-icon-youtube:before {
  content: "\E9C3";
}

.g-icon-alipay-circle:before {
  content: "\E9C2";
}

.g-icon-taobao:before {
  content: "\E9C1";
}

.g-icon-skype:before {
  content: "\E9C0";
}

.g-icon-qq:before {
  content: "\E9BF";
}

.g-icon-medium-workmark:before {
  content: "\E9BE";
}

.g-icon-gitlab:before {
  content: "\E9BD";
}

.g-icon-medium:before {
  content: "\E9BC";
}

.g-icon-linkedin:before {
  content: "\E9BB";
}

.g-icon-google-plus:before {
  content: "\E9BA";
}

.g-icon-dropbox:before {
  content: "\E9B9";
}

.g-icon-facebook:before {
  content: "\E9B8";
}

.g-icon-codepen:before {
  content: "\E9B7";
}

.g-icon-amazon:before {
  content: "\E9B6";
}

.g-icon-google:before {
  content: "\E9B5";
}

.g-icon-codepen-circle:before {
  content: "\E9B4";
}

.g-icon-alipay:before {
  content: "\E9B3";
}

.g-icon-ant-design:before {
  content: "\E9B2";
}

.g-icon-aliyun:before {
  content: "\E9F4";
}

.g-icon-zhihu:before {
  content: "\E703";
}

.g-icon-file-markdown:before {
  content: "\E704";
}

.g-icon-slack:before {
  content: "\E705";
}

.g-icon-slack-square:before {
  content: "\E706";
}

.g-icon-behance:before {
  content: "\E707";
}

.g-icon-behance-square:before {
  content: "\E708";
}

.g-icon-dribbble:before {
  content: "\E709";
}

.g-icon-dribbble-square:before {
  content: "\E70A";
}

.g-icon-instagram:before {
  content: "\E70B";
}

.g-icon-yuque:before {
  content: "\E70C";
}
