.bg {
  background: #272d42;
}
.blue {
  font-size: 14px;
  color: #2F7DF6;
}
.g-input {
  max-width: 100%;
  position: relative;
  border: 1px solid #67759f;
  border-radius: 3px;
  caret-color: #ffffff;
  color: #ffffff;
  transition: all ease-in-out 0.3s;
  height: 40px;
  display: flex;
  align-items: center;
}
.g-input .g-input-suffix,
.g-input .g-input-prefix {
  color: #67759f;
}
.g-input .g-input-suffix i,
.g-input .g-input-prefix i {
  color: #67759f;
}
.g-input.error {
  border-color: #ff5361;
}
.g-input:selection {
  border-color: #2F7DF6;
}
.g-input input,
.g-input textarea {
  max-width: 100%;
  outline: none;
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 24px;
  padding: 0 10px;
  margin: 0;
  appearance: none;
  border: 0;
  background: none;
  color: #ffffff;
}
.g-input input::placeholder,
.g-input textarea::placeholder {
  color: #68778b;
}
.g-input input[type="checkbox"] {
  width: 20px;
  max-width: 20px;
  height: 20px;
  min-height: 20px;
  padding: 0;
  opacity: 0;
}
.g-input input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #1d2431 inset;
  -webkit-text-fill-color: #ffffff !important;
}
.g-input input:-webkit-autofill:focus {
  -webkit-text-fill-color: #ffffff !important;
}
.g-input textarea {
  padding: 10px;
}
.g-input .g-input-suffix {
  color: #67759f;
  margin: 0 10px 0 0;
}
.g-input .g-input-prefix {
  color: #67759f;
  margin: 0 0 0 10px;
}
.g-input-error {
  border-color: #ff5361;
}
.g-input-focus {
  border-color: #ffffff;
  background-color: #2b3555;
}
.g-input-focus .g-input-suffix,
.g-input-focus .g-input-prefix {
  color: #ffffff;
}
.g-input-focus .g-input-suffix i,
.g-input-focus .g-input-prefix i {
  color: #ffffff;
}
.g-checkbox {
  position: relative;
  width: 16px;
  height: 16px;
  padding: 0;
  cursor: pointer;
}
.g-checkbox input {
  cursor: pointer;
}
.g-input-checked {
  background: #2F7DF6;
}
.g-input-checked:before {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 11%;
  top: 13%;
  font-family: iconfontshble;
  content: "\E632";
  display: block;
  color: #ffffff;
  font-size: 12px;
}
.g-input-group {
  position: relative;
  max-width: 100%;
  display: flex;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.g-input-group .g-input {
  border: 0;
  flex: 1;
}
.g-input-group .g-input:last-child {
  border: 0;
}
